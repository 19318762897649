/* SG
# Shapes/Close

Description

```
<h2>16px</h2>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-close is-huge"></div>
  </div>
</div>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-close is-blue is-huge"></div>
  </div>
</div>
<div style="padding: 20px; background: #2E3C43;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .08);">
    <div class="CDB-Shape-close is-white is-huge"></div>
  </div>
</div>
<br>
<br>
<br>
<br>
<h2>12px</h2>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-close is-large"></div>
  </div>
</div>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-close is-blue is-large"></div>
  </div>
</div>
<div style="padding: 20px; background: #2E3C43;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .08);">
    <div class="CDB-Shape-close is-white is-large"></div>
  </div>
</div>
```
*/

.CDB-Shape-close {
  display: block;
  position: relative;
  height: 100%;

  &::before {
    display: block;
    position: absolute;
    width: 1px;
    transform: rotate(-45deg);
    background: $cMainBg;
    content: '';
  }
  &::after {
    display: block;
    position: absolute;
    width: 1px;
    transform: rotate(45deg);
    background: $cMainBg;
    content: '';
  }

  &.is-huge {
    &::before {
      top: -2px;
      left: 7px;
      height: 20px;
    }
    &::after {
      top: -2px;
      right: 8px;
      height: 20px;
    }
  }

  &.is-large {
    &::before {
      top: 2px;
      left: 7px;
      height: 12px;
    }
    &::after {
      top: 2px;
      right: 8px;
      height: 12px;
    }
  }

  &.is-medium {
    &::before {
      top: 3px;
      left: 6px;
      height: 7px;
    }
    &::after {
      top: 3px;
      right: 6px;
      height: 7px;
    }
  }

  &.is-small {
    &::before {
      top: 2px;
      left: 4px;
      height: 5px;
    }
    &::after {
      top: 2px;
      right: 4px;
      height: 5px;
    }
  }
}

.CDB-Shape-close.is-blue {
  &::before {
    background: $cBlue;
  }
  &::after {
    background: $cBlue;
  }
}

.CDB-Shape-close.is-white {
  &::before {
    background: $cWhite;
  }
  &::after {
    background: $cWhite;
  }
}

.CDB-Shape-close.is-red {
  &::before {
    background: $cError;
  }
  &::after {
    background: $cError;
  }
}

// List styles
// ----------------------------------------------

/* SG
# Lists/Lists Decorations


```
  <div class="CDB-Box-modal">&nbsp;</div>
```
*/

.CDB-ListDecoration-item {
  border-bottom: 1px solid $cSecondaryLine;

  &:last-child {
    border-bottom: 0;
  }
}
.CDB-ListDecoration-itemPadding {
  padding: 12px 16px;
}
.CDB-ListDecoration-itemPadding--horizontal {
  padding: 0 16px;
}
.CDB-ListDecoration-itemPadding--vertical {
  padding: 12px 0;
}

.CDB-ListDecoration-itemLink {
  display: block;
  max-width: 100%;
  padding: 12px 16px;
  text-align: left;
}

/* SG
# Shapes/Type

Description

```
<h2>8px</h2>
<div style="padding: 20px;">
  <div class="CDB-Shape CDB-Shape--small">
    <div class="CDB-Type CDB-Type--point">
      <div class="CDB-Type-circle CDB-Type-circle--02"></div>
      <div class="CDB-Type-circle CDB-Type-circle--03"></div>
      <div class="CDB-Type-circle CDB-Type-circle--04"></div>
    </div>
  </div>
</div>
<br/>
<div style="padding: 20px;">
  <div class="CDB-Shape CDB-Shape--small">
    <div class="CDB-Type CDB-Type--line">
      <div class="CDB-Type-circle CDB-Type-circle--02"></div>
      <div class="CDB-Type-circle CDB-Type-circle--03"></div>
    </div>
  </div>
</div>
<br/>
<div style="padding: 20px;">
  <div class="CDB-Shape CDB-Shape--small">
    <div class="CDB-Type CDB-Type--polygon">
      <div class="CDB-Type-circle CDB-Type-circle--01"></div>
      <div class="CDB-Type-circle CDB-Type-circle--02"></div>
      <div class="CDB-Type-circle CDB-Type-circle--03"></div>
      <div class="CDB-Type-circle CDB-Type-circle--04"></div>
    </div>
  </div>
</div>
```
*/

.CDB-Shape {
  width: 16px;
  height: 16px;
  text-align: center;
}
.CDB-Shape--small {
  width: 8px;
  height: 8px;
}
.CDB-Shape--medium {
  width: 24px;
  height: 24px;
}
.CDB-Shape:hover {
  cursor: pointer;
}

.CDB-Type {
  position: relative;
  width: 100%;
  height: 100%;
}

.CDB-Type-circle {
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $cHoverLine;
}
.CDB-Type-circle--01 {
  top: 0;
  left: 0;
}
.CDB-Type-circle--02 {
  top: 0;
  right: 0;
}
.CDB-Type-circle--03 {
  bottom: 0;
  left: 0;
}
.CDB-Type-circle--04 {
  right: 0;
  bottom: 0;
}

.CDB-Type--polygon::before {
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: 1px solid $cHoverLine;
  content: '';
}

.CDB-Type--line::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 3px;
  width: 1px;
  transform: rotate(45deg);
  background: $cHoverLine;
  content: '';
}

// Buttons styles
// ----------------------------------------------

/* SG
# Buttons/Primary

Layout Component:
[CDB-Button][CDB-Button--primary | CDB-Button--secondary]

```

<button class="CDB-Button CDB-Button--loading CDB-Button--primary CDB-Button--big">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon is-white">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>

<button class="CDB-Button CDB-Button--primary CDB-Button--big is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>


<button class="CDB-Button CDB-Button--loading CDB-Button--primary">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon is-white">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>

<button class="CDB-Button CDB-Button--primary is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--loading CDB-Button--primary CDB-Button--small">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon CDB-LoaderIcon--small is-white">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>

<button class="CDB-Button CDB-Button--primary CDB-Button--small is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--primary CDB-Button--icon">
  <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>
</button>

<br/>
<br/>
<br/>

<button class="CDB-Button CDB-Button--alert CDB-Button--big">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--alert CDB-Button--big is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>


<button class="CDB-Button CDB-Button--alert">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--alert is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--alert CDB-Button--small">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--alert CDB-Button--small is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--alert CDB-Button--icon">
  <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>
</button>


<br/>
<br/>
<br/>

<button class="CDB-Button CDB-Button--error CDB-Button--big">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--error CDB-Button--big is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>


<button class="CDB-Button CDB-Button--error">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--error is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--error CDB-Button--small">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--error CDB-Button--small is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<button class="CDB-Button CDB-Button--error CDB-Button--icon">
  <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>
</button>

<br/>
<br/>
<br/>

<div style="background: #2E3C43; padding: 20px;">

  <button class="CDB-Button CDB-Button--primary CDB-Button--big">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary CDB-Button--big is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary CDB-Button--small">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary CDB-Button--small is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--primary CDB-Button--icon">
    <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>
  </button>

</div>

```
*/

.CDB-Button {
  @include transition(background, 300ms);
  position: relative;
  padding: $baseSize $baseSize + 12;
  border: 1px solid transparent;
  border-radius: $baseSize / 2;
  cursor: pointer;
  box-sizing: border-box;


  .CDB-Button-Text {
    display: block;
  }
  &.is-disabled {
    cursor: default;
    opacity: 0.24;
  }
}

.CDB-Button--loading {

  .CDB-Button-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: 50%;
    opacity: 0;
    z-index: 10;
  }
  .CDB-Button-Text {
    @include transition(all 600ms);
  }
  &.is-loading {
    cursor: default;

    .CDB-Button-loader {
      animation-name: showIn;
      animation-duration: 600ms;
      animation-fill-mode: forwards;
    }
    .CDB-Button-Text {
      opacity: 0;
    }
  }
}

@keyframes showIn {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}


.CDB-Button--small {
  padding: $baseSize / 2 $baseSize + 4;
  border-radius: 3px;
}

.CDB-Button--big {
  padding: $baseSize + 3 $baseSize + 12;
}

.CDB-Button--icon {
  padding: 7px 9px;
}

.CDB-Button--primary {
  background: $cBlue;
  color: $cWhite;

  &:hover {
    background: darken($cBlue, 8%);
  }
  &:active {
    background: darken($cBlue, 16%);
  }

  &.is-loading {
    &:hover {
      background: $cBlue;
    }
    &:active {
      background: $cBlue;
    }
  }

  &.is-disabled {
    &:hover {
      background: $cBlue;
    }
    &:active {
      background: $cBlue;
    }
  }
}

.CDB-Button--alert {
  background: $cAlert;
  color: $cWhite;

  &:hover {
    background: darken($cAlert, 8%);
  }
  &:active {
    background: darken($cAlert, 16%);
  }

  &.is-disabled {
    &:hover {
      background: $cAlert;
    }
    &:active {
      background: $cAlert;
    }
  }
}


.CDB-Button--error,
.CDB-Button--negative {
  background: $cError;
  color: $cWhite;

  &:hover {
    background: darken($cError, 8%);
  }
  &:active {
    background: darken($cError, 16%);
  }

  &.is-disabled {
    &:hover {
      background: $cError;
    }
    &:active {
      background: $cError;
    }
  }
}



// Buttons styles
// ----------------------------------------------

/* SG
# Buttons/Secondary

```

<button class="CDB-Button CDB-Button--loading CDB-Button--secondary CDB-Button--big">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon is-blue">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>


<button class="CDB-Button CDB-Button--secondary CDB-Button--big is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>


<button class="CDB-Button CDB-Button--loading CDB-Button--secondary">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon is-blue">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>


<button class="CDB-Button CDB-Button--secondary is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
</button>


<button class="CDB-Button CDB-Button--loading CDB-Button--secondary CDB-Button--small">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  <div class="CDB-Button-loader CDB-LoaderIcon CDB-LoaderIcon--small is-blue">
    <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
      <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
    </svg>
  </div>
</button>

<button class="CDB-Button CDB-Button--secondary CDB-Button--small is-disabled">
  <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
</button>

<br/>
<br/>
<br/>

<div style="background: #2E3C43; padding: 20px;">
  <button class="CDB-Button CDB-Button--loading CDB-Button--secondary CDB-Button--big">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
    <div class="CDB-Button-loader CDB-LoaderIcon is-blue">
      <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
      </svg>
    </div>
  </button>

  <button class="CDB-Button CDB-Button--secondary CDB-Button--big is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--secondary">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--loading CDB-Button--secondary CDB-Button--white">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
    <div class="CDB-Button-loader CDB-LoaderIcon is-white">
      <svg class="CDB-LoaderIcon-spinner" viewbox="0 0 50 50">
        <circle class="CDB-LoaderIcon-path" cx="25" cy="25" r="20" fill="none"/>
      </svg>
    </div>
  </button>

  <button class="CDB-Button CDB-Button--secondary CDB-Button--white is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--secondary is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-medium">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--secondary CDB-Button--small">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>

  <button class="CDB-Button CDB-Button--secondary CDB-Button--small is-disabled">
    <span class="CDB-Button-Text CDB-Text is-semibold CDB-Size-small">SAVE</span>
  </button>
</div>

```
*/


.CDB-Button--secondary {
  border: 1px solid $cBlue;
  color: $cBlue;

  &:hover {
    background: rgba($cBlue, 0.08);
  }
  &:active {
    background: $cBlue;
    color: $cWhite;
  }

  &.is-disabled {
    &:hover {
      background: transparent;
    }
    &:active {
      background: transparent;
    }
  }

  &.is-loading {
    cursor: default;

    &:hover {
      background: none;
      color: $cBlue;
    }
    &:active {
      background: none;
      color: $cBlue;
    }
  }

}


.CDB-Button--white {
  border: 1px solid $cWhite;
  color: $cWhite;

  &:hover {
    background: rgba($cWhite, 0.08);
  }
  &:active {
    background: rgba($cMainBg, 0.08);
    color: $cWhite;
  }

  &.is-disabled {
    @include opacity(0.24);
    &:hover {
      background: transparent;
    }
    &:active {
      background: transparent;
      color: $cWhite;
    }
  }

  &.is-loading {
    cursor: default;

    &:hover {
      background: none;
      color: $cWhite;
    }
    &:active {
      background: none;
      color: $cWhite;
    }
  }

}

// Icon font elements
// ----------------------------------------------

// Do not import any file (variables, mixins,...) in this stylesheet.

@font-face {
  font-family: 'CartoIcon';
  src: url('../fonts/cartoIcon.eot');
  src: url('../fonts/cartoIcon.eot?#iefix') format('embedded-opentype'),
  url('../fonts/cartoIcon.woff') format('woff'),
  url('../fonts/cartoIcon.ttf') format('truetype'),
  url('../fonts/cartoIcon.svg?ey3vsq#cartoIcon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.CDB-IconFont {
  display: inline-block;
  font-family: 'CartoIcon';
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

.CDB-IconFont--center {
  vertical-align: middle;
}
.CDB-IconFont--top {
  vertical-align: top;
}
.CDB-IconFont--small {
  font-size: 11px;
}

/* SG
# Typography/Icon font
All available icons in CartoDB.

```


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-clock Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">clock</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-stats Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">stats</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-heartEmpty Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">heartEmpty</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-heartFill Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">heartFill</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-alert Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">alert</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-rows Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">rows</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-check Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">check</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-dribbble Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">dribbble</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-twitter Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">twitter</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-caretDown Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">caretDown</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-lightbulb Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">lightbulb</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-explore Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">explore</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-drop Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">drop</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-edition Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">edition</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-view Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">view</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-odyssey Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">odyssey</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-settings Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">settings</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-downloadCircle Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">downloadCircle</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-pencilMenu Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">pencilMenu</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mobile Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mobile</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-wizard Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">wizard</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-undo Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">undo</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-redo Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">redo</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-lArrowLight Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">lArrowLight</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-hide Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">hide</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-info Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">info</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-fork Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">fork</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-facebook Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">facebook</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-folder Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">folder</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-dropbox Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">dropbox</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-cloud Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">cloud</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-step Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">step</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-addDocument Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">addDocument</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-arrowNext Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">arrowNext</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">arrowPrev</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-close Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">close</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-cartoFante Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">cartoFante</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-lock Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">lock</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-cartoDB Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">cartoDB</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-lens Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">lens</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-closeLimits Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">closeLimits</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-defaultUser Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">defaultUser</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-cockroach Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">cockroach</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-floppy Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">floppy</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-trash Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">trash</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-wifi Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">wifi</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-unlock Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">unlock</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-unlockWithEllipsis Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">unlockWithEllipsis</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-gift Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">gift</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-people Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">people</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-play Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">play</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-add Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">add</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-map Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">map</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-anchor Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">anchor</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-key Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">key</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-calendar Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">calendar</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-document Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">document</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-eye Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">eye</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-marker Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">marker</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-progressBar Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">progressBar</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-book Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">book</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-notes Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">notes</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-rectangles Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">rectangles</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mountain Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mountain</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-points Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">points</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-snake Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">snake</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-boss Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">boss</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-rocket Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">rocket</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-barometer Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">barometer</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-dollar Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">dollar</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-email Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">email</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-label Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">label</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-outside Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">outside</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-jigsaw Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">jigsaw</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-tools Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">tools</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-question Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">question</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-layerStack Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">layerStack</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-alarm Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">alarm</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-cloudDownArrow Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">cloudDownArrow</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-pencil Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">pencil</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-fountainPen Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">fountainPen</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-emptyDoc Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">emptyDoc</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mergeArrow Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mergeArrow</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mergeColumns Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mergeColumns</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mergeSpatial Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mergeSpatial</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-globe Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">globe</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-chevronDown Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">chevronDown</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-streets Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">streets</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-keys Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">keys</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-facebookSquare Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">facebookSquare</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-fullscreen Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">fullscreen</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-rArrowLight Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">rArrowLight</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-share Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">share</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-linkedin Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">linkedin</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-calendarBlank Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">calendarBlank</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-creativeCommons Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">creativeCommons</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-bubble Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">bubble</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-bars Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">bars</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-arrowMenuLight Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">arrowMenuLight</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-tieFighter Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">tieFighter</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-ray Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">ray</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-markup Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">markup</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-table Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">table</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-airlock Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">airlock</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-pin Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">pin</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-attache Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">attache</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-download Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">download</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-group Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">group</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-dashboard Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">dashboard</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-spinner Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">spinner</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-mapsearch Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">mapsearch</h4>
  </div>


  <div class="box">
    <i class="CDB-IconFont CDB-IconFont-rArrow Size-large"></i>
    <h4 class="Text Size-small u-tSpace--m">rArrow</h4>
  </div>

```
*/



.CDB-IconFont-clock::before {
  content: '\E001';
}


.CDB-IconFont-stats::before {
  content: '\E002';
}


.CDB-IconFont-heartEmpty::before {
  content: '\E003';
}


.CDB-IconFont-heartFill::before {
  content: '\E004';
}


.CDB-IconFont-alert::before {
  content: '\E005';
}


.CDB-IconFont-rows::before {
  content: '\E006';
}


.CDB-IconFont-check::before {
  content: '\E007';
}


.CDB-IconFont-dribbble::before {
  content: '\E008';
}


.CDB-IconFont-twitter::before {
  content: '\E009';
}


.CDB-IconFont-caretDown::before {
  content: '\E00A';
}


.CDB-IconFont-lightbulb::before {
  content: '\E00B';
}


.CDB-IconFont-explore::before {
  content: '\E00C';
}


.CDB-IconFont-drop::before {
  content: '\E00D';
}


.CDB-IconFont-edition::before {
  content: '\E00E';
}


.CDB-IconFont-view::before {
  content: '\E00F';
}


.CDB-IconFont-odyssey::before {
  content: '\E010';
}


.CDB-IconFont-settings::before {
  content: '\E011';
}


.CDB-IconFont-downloadCircle::before {
  content: '\E012';
}


.CDB-IconFont-pencilMenu::before {
  content: '\E013';
}


.CDB-IconFont-mobile::before {
  content: '\E014';
}


.CDB-IconFont-wizard::before {
  content: '\E015';
}


.CDB-IconFont-undo::before {
  content: '\E016';
}


.CDB-IconFont-redo::before {
  content: '\E017';
}


.CDB-IconFont-lArrowLight::before {
  content: '\E018';
}


.CDB-IconFont-hide::before {
  content: '\E019';
}


.CDB-IconFont-info::before {
  content: '\E01A';
}


.CDB-IconFont-fork::before {
  content: '\E01B';
}


.CDB-IconFont-facebook::before {
  content: '\E01C';
}


.CDB-IconFont-folder::before {
  content: '\E01D';
}


.CDB-IconFont-dropbox::before {
  content: '\E01E';
}


.CDB-IconFont-cloud::before {
  content: '\E01F';
}


.CDB-IconFont-step::before {
  content: '\E020';
}


.CDB-IconFont-addDocument::before {
  content: '\E021';
}


.CDB-IconFont-arrowNext::before {
  content: '\E022';
}


.CDB-IconFont-arrowPrev::before {
  content: '\E023';
}


.CDB-IconFont-close::before {
  content: '\E024';
}


.CDB-IconFont-cartoFante::before {
  content: '\E025';
}


.CDB-IconFont-lock::before {
  content: '\E026';
}


.CDB-IconFont-cartoDB::before {
  content: '\E027';
}


.CDB-IconFont-lens::before {
  content: '\E028';
}


.CDB-IconFont-closeLimits::before {
  content: '\E029';
}


.CDB-IconFont-defaultUser::before {
  content: '\E02A';
}


.CDB-IconFont-cockroach::before {
  content: '\E02B';
}


.CDB-IconFont-floppy::before {
  content: '\E02C';
}


.CDB-IconFont-trash::before {
  content: '\E02D';
}


.CDB-IconFont-wifi::before {
  content: '\E02E';
}


.CDB-IconFont-unlock::before {
  content: '\E02F';
}


.CDB-IconFont-unlockWithEllipsis::before {
  content: '\E030';
}


.CDB-IconFont-gift::before {
  content: '\E031';
}


.CDB-IconFont-people::before {
  content: '\E032';
}


.CDB-IconFont-play::before {
  content: '\E033';
}


.CDB-IconFont-add::before {
  content: '\E034';
}


.CDB-IconFont-map::before {
  content: '\E035';
}


.CDB-IconFont-anchor::before {
  content: '\E036';
}


.CDB-IconFont-key::before {
  content: '\E037';
}


.CDB-IconFont-calendar::before {
  content: '\E038';
}


.CDB-IconFont-document::before {
  content: '\E039';
}


.CDB-IconFont-eye::before {
  content: '\E03A';
}


.CDB-IconFont-marker::before {
  content: '\E03B';
}


.CDB-IconFont-progressBar::before {
  content: '\E03C';
}


.CDB-IconFont-book::before {
  content: '\E03D';
}


.CDB-IconFont-notes::before {
  content: '\E03E';
}


.CDB-IconFont-rectangles::before {
  content: '\E03F';
}


.CDB-IconFont-mountain::before {
  content: '\E040';
}


.CDB-IconFont-points::before {
  content: '\E041';
}


.CDB-IconFont-snake::before {
  content: '\E042';
}


.CDB-IconFont-boss::before {
  content: '\E043';
}


.CDB-IconFont-rocket::before {
  content: '\E044';
}


.CDB-IconFont-barometer::before {
  content: '\E045';
}


.CDB-IconFont-dollar::before {
  content: '\E046';
}


.CDB-IconFont-email::before {
  content: '\E047';
}


.CDB-IconFont-label::before {
  content: '\E048';
}


.CDB-IconFont-outside::before {
  content: '\E049';
}


.CDB-IconFont-jigsaw::before {
  content: '\E04A';
}


.CDB-IconFont-tools::before {
  content: '\E04B';
}


.CDB-IconFont-question::before {
  content: '\E04C';
}


.CDB-IconFont-layerStack::before {
  content: '\E04D';
}


.CDB-IconFont-alarm::before {
  content: '\E04E';
}


.CDB-IconFont-cloudDownArrow::before {
  content: '\E04F';
}


.CDB-IconFont-pencil::before {
  content: '\E050';
}


.CDB-IconFont-fountainPen::before {
  content: '\E051';
}


.CDB-IconFont-emptyDoc::before {
  content: '\E052';
}


.CDB-IconFont-mergeArrow::before {
  content: '\E053';
}


.CDB-IconFont-mergeColumns::before {
  content: '\E054';
}


.CDB-IconFont-mergeSpatial::before {
  content: '\E055';
}


.CDB-IconFont-globe::before {
  content: '\E056';
}


.CDB-IconFont-chevronDown::before {
  content: '\E057';
}


.CDB-IconFont-streets::before {
  content: '\E058';
}


.CDB-IconFont-keys::before {
  content: '\E059';
}


.CDB-IconFont-facebookSquare::before {
  content: '\E05A';
}


.CDB-IconFont-fullscreen::before {
  content: '\E05B';
}


.CDB-IconFont-rArrowLight::before {
  content: '\E05C';
}


.CDB-IconFont-share::before {
  content: '\E05D';
}


.CDB-IconFont-linkedin::before {
  content: '\E05E';
}


.CDB-IconFont-calendarBlank::before {
  content: '\E05F';
}


.CDB-IconFont-creativeCommons::before {
  content: '\E060';
}


.CDB-IconFont-bubble::before {
  content: '\E061';
}


.CDB-IconFont-bars::before {
  content: '\E062';
}


.CDB-IconFont-arrowMenuLight::before {
  content: '\E063';
}


.CDB-IconFont-tieFighter::before {
  content: '\E064';
}


.CDB-IconFont-ray::before {
  content: '\E065';
}


.CDB-IconFont-markup::before {
  content: '\E066';
}


.CDB-IconFont-table::before {
  content: '\E067';
}


.CDB-IconFont-airlock::before {
  content: '\E068';
}


.CDB-IconFont-pin::before {
  content: '\E069';
}


.CDB-IconFont-attache::before {
  content: '\E06A';
}


.CDB-IconFont-download::before {
  content: '\E06B';
}


.CDB-IconFont-group::before {
  content: '\E06C';
}


.CDB-IconFont-dashboard::before {
  content: '\E06D';
}


.CDB-IconFont-spinner::before {
  content: '\E06E';
}


.CDB-IconFont-mapsearch::before {
  content: '\E06F';
}


.CDB-IconFont-rArrow::before {
  content: '\E070';
}

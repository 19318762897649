// Sizes variables
// ----------------------------------------------

// Widths
$desktopLayout: 352px;
$mobileLayout: 280px;

//Base
$baseSize: 8px;
$halfBaseSize: $baseSize / 2;

// Margins
$sMargin-section: 24px; // To separate main section
$sMargin-element: 14px; // To separate elements inside a group

// Font sizes
$sFontSize-small: 10px;
$sFontSize-medium: 12px;
$sFontSize-large: 16px;
$sFontSize-huge: 26px;

// Font weights
$sFontWeight-lighter: 300;
$sFontWeight-normal: 400;
$sFontWeight-semibold: 600;

// Line heights
$sLineHeight-small: 14px;
$sLineHeight-medium: 16px;
$sLineHeight-large: 22px;
$sLineHeight-huge: 34px;

// Input styles
// ----------------------------------------------

/* SG
# Forms/Inputs

```
<input type="text" name="text" placeholder="DejaVu Sans" class="CDB-InputText CDB-Text">
<input type="text" name="text" placeholder="DejaVu Sans" class="CDB-InputText has-error CDB-Text">
<input type="text" name="text" placeholder="DejaVu Sans" disabled class="CDB-InputText is-disabled CDB-Text">
```
*/

.CDB-InputText {
  width: 100%;
  min-height: 32px;
  padding: 7px 8px;
  border: 1px solid $cMainLine;
  border-radius: $baseSize / 2;
  font-size: $sFontSize-medium;
  line-height: $sLineHeight-medium;
  box-sizing: border-box;

  &.is-cursor {
    cursor: pointer;
  }
  &.is-empty {
    color: $cHintText;
  }
  &.is-number {
    color: darken($cHighlight, 16%);
  }
  &.is-null {
    color: $cHintText;
    font-style: italic;
  }
  &:hover {
    border: 1px solid $cHoverLine;
  }
  &:focus {
    border: 1px solid $cBlue;
    outline: none;
  }
  &:disabled,
  &.is-disabled {
    border-color: $cSecondaryLine;
    background: $cThirdBackground;
    cursor: default;
  }
  &.has-icon {
    padding-right: $baseSize * 4;
  }
  &.has-error {
    @include default-form-error-style();
  }
}
.CDB-InputTextPlain {
  width: 100%;
  border: 0;
  background: transparent;
  font-size: $sFontSize-medium;
  line-height: $sLineHeight-medium;
  box-sizing: border-box;
}

.CDB-FieldError .CDB-InputText {
  @include default-form-error-style();
}

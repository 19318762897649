// Colors variables
// ----------------------------------------------

// General
$cBlue: #1785FB;
$cBlack: #000;
$cWhite: #FFF;
$cMainBg: #2E3C43;

//Structure
$cSecondaryDark: #282C2F;
$cSecondaryBackground: #F2F6F9;
$cThirdBackground: #F9F9F9;

//Lines
$cHoverLine: #AAA;
$cMainLine: #DDD;
$cSecondaryLine: rgba($cMainBg, 0.08);

//Typography
$cMainText: #2E3C43;
$cSecondaryText: #636D72;
$cAltText: #979EA1;
$cHintText: #CBCED0;

//Others
$cHighlight: #9DE0AD;
$cAlert: #F19243;
$cPublic: #9BC63B;
$cLink: #FEB100;
$cPassword: #FB7B23;
$cError: #F15743;
$cHighlightYellow: #FFFFC0;

// Entry point for all CartoAssets
// ----------------------------------------------

// Variables and functions
@import 'cdb-variables/sizes';
@import 'cdb-variables/colors';
@import 'cdb-utilities/mixins';

@import 'cdb-utilities/vendor/reset';
@import 'cdb-utilities/vendor/normalize';
@import 'cdb-utilities/defaults';
@import 'cdb-utilities/fonts';
@import 'cdb-utilities/helpers';

@import 'vendor/perfect-scrollbar/main'; // Perfect scrollbar styles

@import 'cdb-components/forms/checkbox';
@import 'cdb-components/forms/dropdowns';
@import 'cdb-components/forms/inputs';
@import 'cdb-components/forms/legends';
@import 'cdb-components/forms/option-input';
@import 'cdb-components/forms/radio';
@import 'cdb-components/forms/selects';
@import 'cdb-components/forms/tabsForms';
@import 'cdb-components/forms/textarea';
@import 'cdb-components/forms/toggle';

@import 'cdb-components/shapes/add';
@import 'cdb-components/shapes/arrow';
@import 'cdb-components/shapes/check-circle';
@import 'cdb-components/shapes/close';
@import 'cdb-components/shapes/dash';
@import 'cdb-components/shapes/dataset';
@import 'cdb-components/shapes/dots';
@import 'cdb-components/shapes/error-circle';
@import 'cdb-components/shapes/hamburguer';
@import 'cdb-components/shapes/magnify';
@import 'cdb-components/shapes/move';
@import 'cdb-components/shapes/paragraph';
@import 'cdb-components/shapes/threePoints';
@import 'cdb-components/shapes/toogle-arrow';
@import 'cdb-components/shapes/type';

@import 'cdb-components/avatars';
@import 'cdb-components/boxes';
@import 'cdb-components/buttons';
@import 'cdb-components/colors';
@import 'cdb-components/headers';
@import 'cdb-components/lists';
@import 'cdb-components/loader';
@import 'cdb-components/menu';
@import 'cdb-components/tags';
@import 'cdb-components/tooltips';
@import 'cdb-components/typography';
@import 'cdb-components/layer-selector';

@import 'cdb-icon-font';

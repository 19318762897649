// CheckBoxes styles
// ----------------------------------------------

/* SG
# Forms/Checkbox

```
<div class="u-iBlock">
  <input class="CDB-Checkbox" type="checkbox" name="vehicle" value="Bike" checked>
  <span class="u-iBlock CDB-Checkbox-face"></span>
</div>
<div class="u-iBlock">
  <input class="CDB-Checkbox" type="checkbox" name="vehicle" value="Bus">
  <span class="u-iBlock CDB-Checkbox-face"></span>
</div>
<div class="u-iBlock">
  <input class="CDB-Checkbox" type="checkbox" name="vehicle2" value="Car" disabled>
  <span class="u-iBlock CDB-Checkbox-face"></span>
</div>
```
*/

.CDB-Checkbox {
  position: absolute;
  width: $baseSize * 2;
  height: $baseSize * 2;
  opacity: 0;
  vertical-align: middle;
  z-index: 1;
}

.CDB-Checkbox-face {
  position: relative;
  width: $baseSize * 2;
  height: $baseSize * 2;
  border: 1px solid $cMainLine;
  border-radius: 3px;
  background: $cWhite;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 2;
  pointer-events: none;

  &::before,
  &::after {
    display: block;
    position: absolute;
    bottom: 3px;
    width: 2px;
    border-radius: 1px;
    background: $cWhite;
    content: '';
  }

  &::before {
    right: $baseSize / 2;
    height: $baseSize;
    transform: rotate(45deg);
  }

  &::after {
    left: $baseSize / 2;
    height: $baseSize - 2;
    transform: rotate(-45deg);
  }
}

.CDB-Checkbox:checked + .CDB-Checkbox-face {
  @include transition(background, 300ms);
  border: 1px solid $cBlue;
  background: $cBlue;

  &::before,
  &::after {
    background: $cWhite;
  }
}

.CDB-Checkbox:checked:hover + .CDB-Checkbox-face {
  border: 1px solid $cBlue;
  &::before,
  &::after {
    background: $cWhite;
  }
}

.CDB-Checkbox:hover + .CDB-Checkbox-face {
  border: 1px solid $cHoverLine;
  &::before,
  &::after {
    background: $cHoverLine;
  }
}
.CDB-Checkbox:active + .CDB-Checkbox-face {
  border: 1px solid $cBlue;
  &::before,
  &::after {
    background: $cBlue;
  }
}

.CDB-Checkbox:disabled + .CDB-Checkbox-face {
  border: 1px solid $cSecondaryLine;
  background: $cThirdBackground;
  &::before,
  &::after {
    opacity: 0;
  }
}


// Radios styles
// ----------------------------------------------

/* SG
# Forms/Radio

```
<div class="u-iBlock">
  <input class="CDB-Radio" type="radio" name="gender" value="01" checked>
  <span class="u-iBlock CDB-Radio-face"></span>
</div>
<div class="u-iBlock">
  <input class="CDB-Radio" type="radio" name="gender" value="02">
  <span class="u-iBlock CDB-Radio-face"></span>
</div>
<div class="u-iBlock">
  <input class="CDB-Radio" type="radio" name="gender" value="03" disabled>
  <span class="u-iBlock CDB-Radio-face"></span>
</div>
```
*/

.CDB-Radio {
  position: absolute;
  width: $baseSize * 2;
  height: $baseSize * 2;
  opacity: 0;
  vertical-align: middle;
  z-index: 1;
}

.CDB-Radio-face {
  position: relative;
  width: $baseSize * 2;
  height: $baseSize * 2;
  border: 1px solid $cMainLine;
  border-radius: 50%;
  background: $cWhite;
  text-indent: 20px;
  cursor: pointer;
  box-sizing: border-box;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $baseSize - 2;
    height: $baseSize - 2;
    margin-top: -3px;
    margin-left: -3px;
    border-radius: 50%;
    background: $cWhite;
    content: '';
  }
}

.CDB-Radio:checked + .CDB-Radio-face {
  @include transition(background, 300ms);
  border: 1px solid $cBlue;
  background: $cBlue;

  &::before {
    background: $cWhite;
  }
}

.CDB-Radio:disabled + .CDB-Radio-face {
  border: 1px solid $cSecondaryLine;
  background: $cThirdBackground;

  &::before {
    opacity: 0;
  }
}

.CDB-Radio:disabled:hover + .CDB-Radio-face {
  border: 1px solid $cSecondaryLine;
}

.CDB-Radio:hover + .CDB-Radio-face {
  border: 1px solid $cHoverLine;
  &::before {
    background: $cHoverLine;
  }
}

.CDB-Radio:focus + .CDB-Radio-face,
.CDB-Radio:checked:hover + .CDB-Radio-face {
  border: 1px solid $cBlue;
}

.CDB-Radio:checked:hover + .CDB-Radio-face {
  &::before {
    background: $cWhite;
  }
}

.CDB-Radio:active + .CDB-Radio-face {
  &::before {
    background: $cBlue;
  }
}

/* SG
# Shapes/Dash

Description

```
<h2>16px</h2>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-dash"></div>
  </div>
</div>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-dash is-blue"></div>
  </div>
</div>
<div style="padding: 20px; background: #2E3C43;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .08);">
    <div class="CDB-Shape-dash is-white"></div>
  </div>
</div>
<br>
<br>
<br>
<br>
<h2>12px</h2>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-dash is-small"></div>
  </div>
</div>
<div style="padding: 20px;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .02);">
    <div class="CDB-Shape-dash is-blue is-small"></div>
  </div>
</div>
<div style="padding: 20px; background: #2E3C43;">
  <div class="CDB-Shape" style="background: rgba(0, 0, 0, .08);">
    <div class="CDB-Shape-dash is-white is-small"></div>
  </div>
</div>
```
*/

.CDB-Shape-dash {
  position: relative;
  height: 100%;

  &::before {
    display: block;
    position: absolute;
    right: 2px;
    bottom: 7px;
    left: 2px;
    height: 1px;
    background: $cMainBg;
    content: '';
  }
}

.CDB-Shape-dash.is-small {

  &::before {
    right: 3px;
    left: 3px;
  }
}

.CDB-Shape-dash.is-blue {
  &::before {
    background: $cBlue;
  }
}

.CDB-Shape-dash.is-white {
  &::before {
    background: $cWhite;
  }
}

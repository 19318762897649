// Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSans-Semibold.ttf') format('truetype');
}

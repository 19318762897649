// Typography styles
// ----------------------------------------------

/* SG
# Headers

```
<div class="CDB-HeaderInfo is-block">
  <div class="CDB-HeaderInfo-title u-bSpace">
    <h2 class="CDB-HeaderInfo-titleText CDB-Text CDB-Size-huge is-light">Name Map</h2>
    <div class="CDB-HeaderInfo-Actions">
      <a href="#">
          <div class="CDB-Shape">
            <div class="CDB-Shape-threePoints is-blue is-small">
              <div class="CDB-Shape-threePointsItem"></div>
              <div class="CDB-Shape-threePointsItem"></div>
              <div class="CDB-Shape-threePointsItem"></div>
            </div>
          </div>
      </a>
    </div>
  </div>
  <ul class="CDB-HeaderInfo-List">
    <li class="CDB-HeaderInfo-listItem">
      <button class="CDB-Tag is-public CDB-Text is-semibold CDB-Size-small u-iBlock">
        PUBLIC
      </button>
    </li>
    <li class="CDB-HeaderInfo-listItem">
      <ul class="CDB-AvatarList u-iBlock">
        <li class="CDB-AvatarList-item">
          <img src="/src/img/avatar-40x40.png" alt="User Name" class="CDB-AvatarList-itemMedia" />
        </li>
        <li class="CDB-AvatarList-item">
          <img src="/src/img/avatar-40x40.png" alt="User Name" class="CDB-AvatarList-itemMedia" />
        </li>
        <li class="CDB-AvatarList-item">
          <img src="/src/img/avatar-40x40.png" alt="User Name" class="CDB-AvatarList-itemMedia" />
        </li>
        <li class="CDB-AvatarList-itemMore">
          <button class="CDB-Text is-semibold CDB-Size-small">+ 16</button>
        </li>
      </ul>
    </li>
    <li class="CDB-HeaderInfo-listItem">
      <p class="CDB-HeaderInfo-Text CDB-Text CDB-Size-medium u-iBlock u-altTextColor">Published 8 days ago</p>
    </li>
  </ul>
</div>

<br/>
<br/>
<br/>

<div class="CDB-HeaderInfo">
  <button class="u-rSpace--xl CDB-HeaderInfo-back">
    <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>
  </button>
  <div class="CDB-HeaderInfo-inner">
    <div class="CDB-HeaderInfo-title u-bSpace">
      <h2 class="CDB-HeaderInfo-titleText CDB-Text CDB-Size-huge is-light">Stadiums Affected Stadiums Affected Stadiums AffectedStadiums Affected Stadiums Affected Stadiums Affected Stadiums Affected </h2>
      <ul class="CDB-HeaderInfo-Actions">
        <li class="CDB-HeaderInfo-actionsItem">
          <button class="CDB-Button CDB-Button--primary CDB-Button--icon">
            <i class="CDB-IconFont CDB-IconFont-ray CDB-Size-small"></i>
          </button>
        </li>
        <li class="CDB-HeaderInfo-actionsItem">
          <a href="#">
              <div class="CDB-Shape">
                <div class="CDB-Shape-threePoints is-blue is-small">
                  <div class="CDB-Shape-threePointsItem"></div>
                  <div class="CDB-Shape-threePointsItem"></div>
                  <div class="CDB-Shape-threePointsItem"></div>
                </div>
              </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="CDB-Shape u-iBlock">
      <ul class="CDB-Shape-Dataset is-small is-grey">
        <li class="CDB-Shape-DatasetItem"></li>
        <li class="CDB-Shape-DatasetItem"></li>
      </ul>
    </div>
    <p class="CDB-Text CDB-Size-medium u-iBlock"><a href="#" class="u-iBlock">populates_places</a></p>
  </div>
</div>

<br/>
<br/>
<br/>

<div class="CDB-HeaderInfo">
  <button class="u-rSpace--xl CDB-HeaderInfo-back">
    <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>
  </button>
  <div class="CDB-HeaderInfo-inner">
    <div class="CDB-HeaderInfo-title u-bSpace">
      <h2 class="CDB-HeaderInfo-titleText CDB-Text CDB-Size-huge is-light">Basemap</h2>
      <div class="CDB-HeaderInfo-actions">
        <a href="#">
            <div class="CDB-Shape">
              <div class="CDB-Shape-threePoints is-blue is-small">
                <div class="CDB-Shape-threePointsItem"></div>
                <div class="CDB-Shape-threePointsItem"></div>
                <div class="CDB-Shape-threePointsItem"></div>
              </div>
            </div>
        </a>
      </div>
    </div>
    <p class="CDB-HeaderInfo-Text CDB-Text CDB-Size-medium u-altTextColor">Positron by CartoDB</p>
  </div>
</div>

<br/>
<br/>
<br/>

<div class="CDB-HeaderInfo">
  <button class="u-rSpace--xl CDB-HeaderInfo-back">
    <i class="CDB-IconFont CDB-IconFont-arrowPrev Size-large"></i>
  </button>
  <div class="CDB-HeaderInfo-inner">
    <input type="text" name="text" placeholder="DejaVu Sans" class="CDB-InputText CDB-Text u-bSpace--m">
    <div class="CDB-Shape u-iBlock">
      <ul class="CDB-Shape-Dataset is-small is-grey">
        <li class="CDB-Shape-DatasetItem"></li>
        <li class="CDB-Shape-DatasetItem"></li>
      </ul>
    </div>
    <p class="CDB-Text CDB-Size-medium u-iBlock"><a href="#" class="u-iBlock">populates_places</a></p>
  </div>
</div>

<br/>
<br/>
<br/>

<div class="CDB-HeaderInfo">
  <div class="CDB-HeaderNumeration CDB-Text is-semibold u-rSpace--m">1</div>

  <div class="CDB-HeaderInfo-inner CDB-Text">
    <div class="CDB-HeaderInfo-title u-bSpace--m">
      <h2 class="CDB-Text CDB-HeaderInfo-titleText CDB-Size-large">Type</h2>

      <div class="CDB-HeaderInfo-actions">
        <a href="#">
          <div class="CDB-Shape">
            <div class="CDB-ArrowToogle is-blue is-small is-down"></div>
          </div>
        </a>
      </div>
    </div>

    <p class="CDB-Text u-upperCase CDB-FontSize-small u-altTextColor u-bSpace--m">Histogram</p>
    <p class="CDB-Text CDB-Size-medium u-altTextColor">Distribution of a numeric variable in a range</p>
  </div>
</div>

<br/>
<br/>
<br/>
```
*/

.CDB-HeaderInfo {
  @include display-flex();
  @include align-items(flex-start);

  &.is-block {
    display: block;
  }
}
.CDB-HeaderInfo-listItem {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;

  &:last-child {
    margin-right: 0;
  }
}

.CDB-HeaderInfo-inner {
  @include flex(1);
}
.CDB-HeaderInfo-back {
  margin-top: 7px;
}
.CDB-HeaderInfo-title {
  @include display-flex();
  @include align-items(center);
}
.CDB-HeaderInfo-titleText {
  @include flex(1);
  width: 0;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.CDB-HeaderInfo-actionsItem {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}
.CDB-HeaderNumeration {
  width: $baseSize * 3;
  min-width: $baseSize * 3;
  height: $baseSize * 3;
  border: 1px solid $cMainLine;
  border-radius: 4px;
  background-color: $cWhite;
  color: $cAltText;
  font-size: $sFontSize-small;
  line-height: 23px;
  text-align: center;
  box-sizing: border-box;
}

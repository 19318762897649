// Menu styles
// ----------------------------------------------

/* SG
# Boxes/Dropdown


```
  <div class="CDB-Box-modal">&nbsp;</div>
```
*/

.CDB-Box-modal {
  min-width: 160px;
  border: 1px solid $cMainLine;
  border-radius: 4px;
  background: $cWhite;
  box-shadow: 0  $baseSize  $baseSize * 2 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
}

.CDB-Box-modalHeader {
  background: $cSecondaryBackground;
}
.CDB-Box-modalHeaderItem {
  @include display-flex();
  padding: $baseSize + 4 $baseSize * 2;
  border-bottom: 1px solid $cMainLine;
}
.CDB-Box-modalHeaderItem--block {
  display: block;
}
.CDB-Box-modalHeaderItem--paddingHorizontal {
  padding: 0 $baseSize * 2;
}
.CDB-Box-modalHeaderItem--paddingVertical {
  padding: $baseSize + 4 0;
}
